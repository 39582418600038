/// <reference types="spotify-api" />
import { useContext, useEffect, useState } from "react"
import firebase from "firebase/app";
import "firebase/database";
import Track from "./Track";
import AuthContext from "./AuthContext";
import { Button, Loading } from '@geist-ui/react';
import _ from "lodash";

const url = "https://us-central1-spotstats.cloudfunctions.net/spotstats-get-tracks";
const PAGE_SIZE = 25;
const DEBOUNCE_MS = 300;

function track(track: TrackData, i: number) {
    return <Track key={track.key} track={track}></Track>
}

export interface TrackData {
    key?: string | null,
    Track: string,
    Timestamp: string,
    spotify?: SpotifyApi.TrackObjectFull
}
    
function makeTrack(event: firebase.database.DataSnapshot): TrackData {
    return {
        key: event.key,
        Track: event.val().Track,
        Timestamp: event.val().Timestamp,
    };
}


function TrackList() {
    const [ tracks, addTrack ] = useTrackAdder([]);
    const [ isLoading, setLoading ] = useState(false);
    const [ pageLimit, setPageLimit] = useState(PAGE_SIZE);
    const { user } = useContext(AuthContext)
    
    function useTrackAdder(initialState: TrackData[]) : [TrackData[], (t: TrackData) => void] {
        let [state, setState] = useState(initialState);
    
        let queue: TrackData[] = [];
        let dispatcher = _.debounce(async () => {
            let enriched = await enrichTrack(queue);
            queue = [];
            setState( (actual) => _.orderBy(_.uniqBy([...actual, ...enriched], "key"), "Timestamp", "desc"));
            setLoading(false);
        }, DEBOUNCE_MS);
    
        function enricherDispatcher(track: TrackData) {
            if(!_.find(state, (t) => t.key === track.key)) {
                queue.push(track);
                dispatcher();
            }
        }
    
        return [state, enricherDispatcher];
    }

    useEffect(() => {
        let ref = firebase.database().ref("/tracklist");
        let handler = (event: firebase.database.DataSnapshot) => {
            addTrack(makeTrack(event));
        };
        ref.orderByChild("Timestamp")
        .limitToLast(pageLimit)
        .on('child_added', handler , console.error);
        return () => ref.off('child_added', handler)
    }, [pageLimit]); // eslint-disable-line

    async function enrichTrack(newTracks: TrackData[]): Promise<TrackData[]> {

        let trackQuery = newTracks.map((t) => t.Track).join(",");
        let nUrl = new URL(url);
        nUrl.search = new URLSearchParams({ tracks: trackQuery }).toString();
        let token = await user?.getIdToken();
        let resp = await fetch(nUrl.toString(), {
            headers: new Headers({"Authorization": `token ${token}`})
        });
        if(resp.status === 200) {
            let results: SpotifyApi.MultipleTracksResponse = await resp.json();
            return newTracks.map((t, i) => {
                t.spotify = results.tracks[i]
                return t
            });
        } else {
            console.error(`enrich error ${resp.status}:${resp.statusText}`);
        }
        return []
    }

    function loadMore() {
        setPageLimit(pageLimit + PAGE_SIZE)
        setLoading(true);
    }

    return (

        <ul>
            {tracks.map(track)}
            { tracks.length > 0 && 
                <li><Button loading={isLoading} onClick={loadMore}>More</Button></li>
            }
            { tracks.length === 0 &&
                <li><Loading size="large" type="error" >Loading</Loading></li>
            }
        </ul>
    )
}

export default TrackList
