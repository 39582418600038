import { TrackData } from "./TrackList";
import { Image, Divider } from '@geist-ui/react';


interface TrackProps {
    track: TrackData
}

const desiredImageSize = 64
function findAlbumArt(images: SpotifyApi.ImageObject[]): SpotifyApi.ImageObject | undefined  {
   return images.find((im) => {
        return im.height === desiredImageSize
    })
}

function Track(props: TrackProps) {
    if(props.track.spotify) {
        let metadata: SpotifyApi.TrackObjectFull = props.track.spotify
        let albumArt = findAlbumArt(metadata.album.images)
        return (
            <li className="Track-item">
                <div className="Track-container">
                    { albumArt && 
                        <div className="Track-albumart">
                            <Image width={albumArt.width} height={albumArt.height} src={albumArt.url}></Image> 
                        </div>
                    }
                    <div className="Track-label">
                        <a href={metadata.external_urls.spotify}>
                            {metadata.artists[0].name} - {metadata.name}
                        </a>
                    </div>
                </div>
                <Divider/>
            </li>
        )
    }
    return <li className="Track-item">Track {props.track.Track} not found</li>
}

export default Track;