import { Row, Modal, useModal, Input } from '@geist-ui/react';
import { useContext, useEffect, useState } from 'react';
import Captcha from './Captcha';
import firebase from "firebase/app";
import AuthContext from './AuthContext';

type LoginModalProps = {
    visible: boolean,
    close: () => void,
    onComplete: () => void,
}

function LoginModal(props: LoginModalProps) {
    const {setVisible, bindings } = useModal()
    const [ captchaVerifier, setCaptchaVerifier ] = useState<firebase.auth.ApplicationVerifier>()
    const [ phone, setPhone ] = useState("")
    const [ code, setCode ] = useState("")
    const [ confirmResult, setConfirmResult ] = useState<firebase.auth.ConfirmationResult>();
    const { setUser } = useContext(AuthContext);

    useEffect( () => {
        setVisible(props.visible)
    }, [ setVisible, props.visible])

    function handlePhone(event: any) {
        if(phone.indexOf("+1") >= 0) {
            setPhone(event.target.value);
        } else {
            setPhone("+1 " + event.target.value);
        }
    }

    function handleCode(event: any) {
        setCode(event.target.value);
    }

    async function submit() {
        console.log('submitting form with ', phone)
        try {
            if(captchaVerifier) {
                await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
                let result = await firebase.auth().signInWithPhoneNumber(phone, captchaVerifier);
                setConfirmResult(result)
            } else {
                throw Error("Captcha not initialized");
            }
        } catch(err) {
            console.error("Failed to auth", err);
        }        
    }

    async function submitCode() {
        if(confirmResult) {
            let user = await confirmResult.confirm(code)
            if(user.user) {
                setUser(user.user);
            }
            console.log(user);
            props.close()
            setConfirmResult(undefined);
        }
    }

    function cancel() {
        props.close();
        setConfirmResult(undefined);
    }

    return (
        <div id="login">
        <Captcha enabled={props.visible} setVerifier={setCaptchaVerifier}></Captcha>
        <Modal { ...bindings }>
            <Modal.Title>SpotStats</Modal.Title>
            { confirmResult == null
              ? <Modal.Subtitle>Sign in with your phone</Modal.Subtitle>
              : <Modal.Subtitle>Enter the verification code</Modal.Subtitle>
            }
            <Modal.Content>
            <Row justify="center">
                { confirmResult == null
                  ? <Input label="Phone" type="tel" value={phone} onChange={handlePhone} placeholder="+1 650-555-5555" />
                  : <Input label="Verification Code" type="number" value={code} onChange={handleCode} placeholder="000000" />
                }
            </Row>
            </Modal.Content>
            <Modal.Action passive onClick={cancel}>Cancel</Modal.Action>
            { confirmResult == null
              ? <Modal.Action id="signin-submit" onClick={submit}>Submit</Modal.Action>
              : <Modal.Action id="signin-submit" onClick={submitCode}>Submit</Modal.Action>
            }
        </Modal>
        </div>
    )
}

export default LoginModal