import './App.css';
import firebase from "firebase/app";
import "firebase/auth";
import { Page, Row, Link } from '@geist-ui/react';
import LoginModal from './LoginModal';
import { useState } from 'react'
import AuthContext from './AuthContext';
import TrackList from './TrackList';


var firebaseConfig = {
  apiKey: "AIzaSyAxShsVn28mPIbhlxkTuPl4778Vi_F-bd4",
  authDomain: "spotstats.firebaseapp.com",
  databaseURL: "https://spotstats-default-rtdb.firebaseio.com",
  projectId: "spotstats",
  storageBucket: "spotstats.appspot.com",
  messagingSenderId: "666757643125",
  appId: "1:666757643125:web:156474827598ebb5fcf13c"
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} 


function App() {
  
  const [loginVisible, setLoginVisible] = useState(false);
  const [user, setUser] = useState<firebase.User | null>(null);

  firebase.auth().onAuthStateChanged((user) => {
    setUser(user);
  }); 

  function onSignInSubmit() {
    setLoginVisible(false);
  }

  async function logout() {
    try {
      await firebase.auth().signOut()
    } catch(err) {
      console.error("logout error", err)
    }
    setUser(null);
  }

  return (
    <AuthContext.Provider value={{user,setUser}}>
      <div className="App">
        <Page size="medium">
          <Page.Header className="App-header">
            <Row justify="end">
              { user  
                ? <Link id="signin-link" href="#" onClick={logout}>Logout</Link> 
                : <Link id="signin-link" href="#" onClick={() => setLoginVisible(true)}>Sign In</Link> 
              }
              
            </Row>
          </Page.Header>
          <Page.Content>
            { user && 
              <TrackList/>
            }
          </Page.Content>
        </Page>
        <LoginModal visible={loginVisible} onComplete={onSignInSubmit} close={ () => setLoginVisible(false) } />
      </div>
    </AuthContext.Provider>
  );
}

export default App;
