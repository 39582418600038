import firebase from "firebase/app";
import { useEffect, useState } from "react";

function Captcha(props) {

    const [ verifier, setVerifier ] = useState(null)
    
    useEffect(() => {
        if(props.enabled && verifier === null) {
            console.log("rendering captcha", verifier)
            const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
                'size': 'invisible',
            })
            recaptchaVerifier.render()
            setVerifier(recaptchaVerifier)
            props.setVerifier(recaptchaVerifier)
        } else {
            setVerifier(null)
            props.setVerifier(null)

        }
    }, [props.enabled]) // eslint-disable-line

    if(props.enabled){
        return (
            <div id="recaptcha"></div>
        )
    } else {
        return null
    }
}

export default Captcha