import React from "react";
import firebase from "firebase/app";

type UserContext = {
    user: firebase.User | null,
    setUser: (u: firebase.User) => void
}

const AuthContext = React.createContext<UserContext>({
    user: null,
    setUser: (u: firebase.User) => u
})
export default AuthContext
export type { UserContext }